/* JS */

/* $(function() {
    $(document).ready(function() {
        $(".loader-center").css("display", "none");
    });
}); */

const updateTimer = (deadline) => {
    // calculates time left until deadline
    const time = deadline - new Date();
    return {
        days: Math.floor(time / (1000 * 60 * 60 * 24)),
        hours: Math.floor((time / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((time / (1000 * 60)) % 60),
        seconds: Math.floor((time / 1000) % 60),
        total: time,
    };
};

const animateClock = (span) => {
    span.className = "turn";
    setTimeout(() => {
        span.className = "";
    }, 500);
};

const startTimer = (id, deadline, custom) => {
    // calls updateTimer every second
    const timeInterval = setInterval(() => {
        showTimer(id, deadline, custom);
    }, 1000);
};

function showTimer(id, deadline, custom) {
    //const clock = document.getElementById(id);
    /* get clock by id and custom */
    const clock = $("#" + id + "." + custom)[0];

    if (clock) {
        let timer = updateTimer(deadline);

        clock.innerHTML =
            "<span>" +
            timer.days +
            "<p>NAP</p></span>" +
            "<span>" +
            timer.hours +
            "<p>ÓRA</p></span>" +
            "<span>" +
            timer.minutes +
            "<p>PERC</p></span>" +
            "<span>" +
            timer.seconds +
            "<p>MP</p></span>";

        const spans = clock.getElementsByTagName("span");
        animateClock(spans[3]);
        if (timer.seconds == 59) animateClock(spans[2]);
        if (timer.minutes == 59 && timer.seconds == 59) animateClock(spans[1]);
        if (timer.minutes == 23 && timer.minutes == 59 && timer.seconds == 59)
            animateClock(spans[0]);
        if (timer.total < 1) {
            clearInterval(timeInterval);
            clock.innerHTML =
                "<span>0</span><span>0</span><span>0</span><span>0</span>";
        }
    }
}

window.onload = () => {
    const currentDate = new Date();

    const date2022 = "October 20, 2022 11:00:00";
    const dated2022 = new Date(date2022);

    if (currentDate < dated2022) {
        const deadline = new Date(date2022);
        showTimer("clock", deadline, "clock2022");
        startTimer("clock", deadline, "clock2022");
    }
};

$(document).ready(function () {
    $(".mmcontainer").click(function () {
        this.classList.toggle("change");
        $("#mobile-menu").toggleClass("menu-hidden", 800, "easeOutQuint");
    });
});
